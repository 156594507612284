/*
 * @Author: N0ts
 * @Date: 2022-11-09 09:34:03
 * @Description: 滚动条监听
 * @FilePath: /dongxiaodi-web/src/hooks/scrollListen.ts
 * @Mail：mail@n0ts.cn
 */

let lock = false;
export default function scrollListen(app: any) {
    app.directive("scrollListen", (el: any, binding: any) => {
        async function scrollFunc(e: any) {
            if (lock) {
                return;
            }
            lock = true;
            setTimeout(async () => {
                const height = e.target.scrollHeight;
                const clientHeight = e.target.clientHeight;
                const scrollHeight = e.target.scrollTop;
                if (height - scrollHeight - clientHeight <= 100) {
                    await binding.value(() => {
                        lock = false;
                    });
                } else {
                    lock = false;
                }
            }, 50);
        }
        el.removeEventListener("scroll", scrollFunc);
        el.addEventListener("scroll", scrollFunc);
    });
}
