/*
 * @Author: N0ts
 * @Date: 2022-10-21 00:24:45
 * @Description: 路由配置
 * @FilePath: \dongxiaodi-web\src\router\index.ts
 * @Mail：mail@n0ts.cn
 */

import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import cache from "@/utils/cache";

// 首页
const PageHome = () => import("@/views/pageHome.vue");
// 武器列表
const ArmsList = () => import("@/views/armsList.vue");
// 分类
const Category = () => import("@/views/categoryList.vue");
// 厂商
const Firm = () => import("@/views/firmList.vue");
// 活动
const Activity = () => import("@/views/activityList.vue");
// 我的
const MePage = () => import("@/views/mePage.vue");
// 武器详情
const ArmsInfo = () => import("@/views/armsInfo.vue");
// 搜索页面
const SearchPage = () => import("@/views/searchPage.vue");
// 后台管理首页
const ManageIndex = () => import("@/views/manage/indexPage.vue");
// 后台武器管理
const ManageArmsList = () => import("@/views/manage/armsList.vue");
// 后台分类管理
const ManageCategoryList = () => import("@/views/manage/categoryList.vue");
// 后台厂商管理
const ManageFirmList = () => import("@/views/manage/firmList.vue");
// 后台活动管理
const ManageActivityList = () => import("@/views/manage/activityList.vue");
// 分享页面
const SharePage = () => import("@/views/sharePage.vue");

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: PageHome,
        children: [
            {
                path: "/",
                component: ArmsList,
                meta: {
                    navIndex: 1,
                    name: "首页"
                }
            },
            {
                path: "/category",
                component: Category,
                meta: {
                    navIndex: 2,
                    name: "分类列表"
                }
            },
            {
                path: "/firm",
                component: Firm,
                meta: {
                    navIndex: 3,
                    name: "厂商列表"
                }
            },
            {
                path: "/activity",
                component: Activity,
                meta: {
                    navIndex: 4,
                    name: "活动列表"
                }
            },
            {
                path: "/me",
                component: MePage,
                meta: {
                    navIndex: 5,
                    name: "我的"
                }
            }
        ]
    },
    {
        path: "/arms/:id",
        component: ArmsInfo,
        meta: {
            name: "产品详情"
        }
    },
    {
        path: "/search",
        component: SearchPage,
        meta: {
            name: "搜索"
        }
    },
    {
        path: "/manage",
        component: ManageIndex,
        meta: {
            name: "后台"
        }
    },
    {
        path: "/manage/arms",
        component: ManageArmsList,
        meta: {
            name: "产品管理"
        }
    },
    {
        path: "/manage/category",
        component: ManageCategoryList,
        meta: {
            name: "分类管理"
        }
    },
    {
        path: "/manage/firm",
        component: ManageFirmList,
        meta: {
            name: "厂商管理"
        }
    },
    {
        path: "/manage/activity",
        component: ManageActivityList,
        meta: {
            name: "活动管理"
        }
    },
    {
        path: "/share",
        component: SharePage,
        meta: {
            name: "分享收藏"
        }
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from) => {
    document.title = `[${to.meta.name}] 東小帝的玩具整合评测`;

    // 是否登录
    if (
        to.path.includes("manage") &&
        !cache.localStorage.get("user") &&
        to.path !== "/me"
    ) {
        // 将用户重定向到登录页面
        return "/me";
    }

    // 如果为管理员页面则放大
    // const root: any = document.querySelector("#root");
    // if (root) {
    //     root.style.width = to.path.includes("manage") ? "800px" : "450px";
    // }
});

export default router;
