import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "root",
  "element-loading-text": "拼命加载中..."
}

import loading from "@/api/axios/loading";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view)
  ])), [
    [
      _directive_loading,
      _unref(loading),
      void 0,
      {
        fullscreen: true,
        lock: true
      }
    ]
  ])
}
}

})