/*
 * @Author: N0ts
 * @Date: 2022-10-22 12:01:27
 * @Description: 缓存操作
 * @FilePath: /dongxiaodi-web/src/utils/cache.ts
 * @Mail：mail@n0ts.cn
 */

export default {
    localStorage: {
        set(key: string, value: any) {
            window.localStorage.setItem(key, JSON.stringify(value));
        },
        get(key: string) {
            const cache = window.localStorage.getItem(key);
            if (cache) {
                return JSON.parse(cache);
            }
            return null;
        },
        remove(key: string) {
            window.localStorage.removeItem(key);
        }
    },
    sessionStorage: {
        set(key: string, value: any) {
            window.sessionStorage.setItem(key, JSON.stringify(value));
        },
        get(key: string) {
            const cache = window.sessionStorage.getItem(key);
            if (cache) {
                return JSON.parse(cache);
            }
            return null;
        },
        remove(key: string) {
            window.sessionStorage.removeItem(key);
        }
    }
};
