/*
 * @Author: N0ts
 * @Date: 2022-10-21 00:24:45
 * @Description: main
 * @FilePath: /dongxiaodi-web/src/main.ts
 * @Mail：mail@n0ts.cn
 */

import { createApp } from "vue";
import App from "./App.vue";

// Element Plus
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

// Vue Router
import router from "./router";

// 全局样式
import "@/assets/css/global.less";
import "@/assets/css/edit.less";
import "viewerjs/dist/viewer.css";

// hooks
import dateFormat from "@/hooks/date";
import scrollListen from "@/hooks/scrollListen";

const app = createApp(App);
app.use(ElementPlus).use(router);
dateFormat(app);
scrollListen(app);
app.mount("#app");
